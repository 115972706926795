import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { useId } from 'react';
import { colors, columnSize } from '../../utils/css';
import { DarkThemeProvider } from '../../context/ThemeContext.jsx';
import { CopyrightInfo, DateTimeInfo } from '../Info/Info.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { FooterLinkInteraction } from '../Interaction/Interaction.jsx';
import { Link } from '../Link/Link.jsx';
import { getUrlWithFallbackToHomePage } from '../../utils/url/url';
import { StyledFooterLink, footerLinkFragment } from './FooterLink.jsx';
import { NavigationSectionHeadline } from '../Headline/Headline.jsx';
import { SocialMediaAccountLink } from './SocialMediaAccountLink.jsx';
import { I18nText } from '../I18n/I18nText.jsx';
import { memoizeConst } from '../../utils/memoize';

const fragmentName = 'Footer';
export const footerFragment = {
  name: fragmentName,
  fragment: memoizeConst(
    () => gql`
      fragment ${fragmentName} on Query {
        footer(lang: $lang) {
          sections {
            headline
            links {
              ...${footerLinkFragment.name}
            }
          }
          linkedSocialMediaAccounts {
            service
            url
          }
          policy {
            id
            localeLang
            name
          }
          legal {
            id
            localeLang
            name
          }
          accessibility {
            id
            localeLang
            name
          }
          privacySettings {
            title
            namedUrl
          }
        }
      }
      ${footerLinkFragment.fragment()}
    `,
  ),
};

const FooterSection = ({ headline, children }) => {
  const sectionId = useId();

  return (
    <ul role="group" aria-labelledby={sectionId}>
      <NavigationSectionHeadline id={sectionId}>{headline}</NavigationSectionHeadline>
      <ul>{children}</ul>
    </ul>
  );
};

// @used in ROAD
export const Footer = ({ className, data: { footer } }) => {
  const currentYear = new Date().getFullYear();
  const socialMediaHeadline = useTranslation('footer.socialmediachannels.headline');

  return (
    <DarkThemeProvider>
      <footer className={className}>
        <h2 className="sr-only">Footer</h2>

        <section className="footer-main" data-tracking-name="footer-section-main">
          {footer.sections?.map(section => (
            <FooterSection key={section.headline} headline={section.headline}>
              {section.links?.map(link => (
                <StyledFooterLink link={link} key={link.text} />
              ))}
            </FooterSection>
          ))}
          <FooterSection headline={socialMediaHeadline}>
            {footer.linkedSocialMediaAccounts?.map(socialMediaAccount => (
              <SocialMediaAccountLink
                key={socialMediaAccount.service}
                socialMediaAccount={socialMediaAccount}
              />
            ))}
          </FooterSection>
        </section>

        <section className="footer-standard" data-tracking-name="footer-section-standard-links">
          <CopyrightInfo>© {currentYear} Deutsche Welle</CopyrightInfo>

          <DateTimeInfo>|</DateTimeInfo>
          <I18nText
            isA={FooterLinkInteraction}
            as={Link}
            translation="footer.policy"
            to={getUrlWithFallbackToHomePage(footer.policy)}
          />

          <DateTimeInfo>|</DateTimeInfo>
          <I18nText
            isA={FooterLinkInteraction}
            as={Link}
            translation="footer.legal"
            to={getUrlWithFallbackToHomePage(footer.legal)}
          />

          <DateTimeInfo>|</DateTimeInfo>
          <I18nText
            isA={FooterLinkInteraction}
            as={Link}
            translation="footer.accessibility"
            to={getUrlWithFallbackToHomePage(footer.accessibility)}
          />

          <DateTimeInfo>|</DateTimeInfo>
          <FooterLinkInteraction
            as={Link}
            to={footer.privacySettings?.namedUrl}
            title={footer.privacySettings?.title}
            target="_blank"
          >
            {footer.privacySettings?.title}
          </FooterLinkInteraction>
        </section>
      </footer>
    </DarkThemeProvider>
  );
};

export const StyledFooter = styled(Footer)`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${colors.DARK_BLUE_GREY_01};

  > .footer-main {
    display: grid;
    grid-template-columns: ${columnSize.c14} 1fr;
    padding-left: ${columnSize.c2};
    padding-right: ${columnSize.c2};

    > ul {
      margin-bottom: 30px;
      padding: 0;

      > ul {
        list-style: none;
        padding: 0;
      }

      &:last-child {
        margin-bottom: 20px;
        grid-column: span 2;
        padding: 0;

        > ul {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }

  > .footer-standard {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    padding-left: ${columnSize.c2};
    padding-right: ${columnSize.c2};

    > * {
      white-space: nowrap;
    }

    > ${DateTimeInfo}:not(:first-child) {
      margin: 0 5px;
    }
  }
`;
