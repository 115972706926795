import { useRef } from 'react';
import { styled } from '@linaria/react';
import { StyledNavMenu as NavMenu } from './NavMenu/NavMenu.jsx';
import { NavigationContextProvider, useNavigationContext } from './NavigationContext.jsx';
import { useOnFocusout } from '../../../hooks/useOnFocusout';
import { keyCodes } from '../../../constants/keyCodes';
import { StyledBanner as Banner } from '../Banner.jsx';

const NavigationContextWrapper = props => (
  <NavigationContextProvider>
    <NavigationContainer {...props} />
  </NavigationContextProvider>
);

const NavigationContainer = ({ className }) => {
  const { closeMenu } = useNavigationContext();

  /** @type {React.MutableRefObject<HTMLElement | undefined>} */
  const navRef = useRef();
  useOnFocusout(navRef, closeMenu);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <nav
      className={className}
      ref={navRef}
      onKeyDown={e => {
        if (e.key === keyCodes.ESCAPE) {
          closeMenu();
        }
      }}
    >
      <Banner />
      <NavMenu />
    </nav>
  );
};

export const StyledNavigationContainer = styled(NavigationContextWrapper)`
  position: fixed;
  left: 0px;
  top: 0px;
  height: 4rem;
  width: 100%;
  z-index: 1000;
`;
