import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { GTM_NO_CONTENT_PAGES } from '../../constants/pageTypes';
import { MainPage } from '../../pages/MainPage.jsx';
import { userLoading } from '../../state/user/userSelectors';
import { ConsentManagement } from '../ConsentManagement/ConsentManagement.jsx';
import { StyledFooter as Footer } from '../Footer/Footer.jsx';
import { GtmScriptWithDataLayer } from '../GoogleTagManager';
import { Header } from '../Header/Header.jsx';
import { I18nConfigLoader } from '../I18n/I18nConfigLoader.jsx';
import { JsonLdScript } from '../JsonLd/JsonLdScript.jsx';
import { StyledLoadingMessage } from '../commons/LoadingMessage.jsx';
import { PageMetaData } from './PageMetaData.jsx';
import { PageLanguage } from './PageLanguage.jsx';

export const PageContainer = ({ children }) => {
  const userIsLoading = useSelector(userLoading);

  return (
    <MainPage>
      <I18nConfigLoader>
        {data => (
          <>
            <Header />
            <PageLanguage />
            <JsonLdScript />
            {userIsLoading ? (
              <StyledLoadingMessage />
            ) : (
              <Suspense fallback={<StyledLoadingMessage />}>{children}</Suspense>
            )}
            <Footer data={data} />
            <ConsentManagement data={data} />
          </>
        )}
      </I18nConfigLoader>
    </MainPage>
  );
};

/**
 * @param {React.ComponentProps<typeof PageMetaData>} props
 */
export const MetadataAndGtm = ({ children, pageType, ...pageMetaDataProps }) => (
  <>
    <PageMetaData pageType={pageType} {...pageMetaDataProps} />
    <GtmScriptWithDataLayer noContentPageName={GTM_NO_CONTENT_PAGES[pageType]} />
    {children}
  </>
);
