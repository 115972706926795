import { styled } from '@linaria/react';
import { colors, mediaMin } from '../utils/css';

const SpinnerIcon = ({ className }) => (
  <svg
    className={className}
    data-testid="spinner"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
  >
    <circle
      className="first-circle"
      transform="translate(32 32)"
      fill="none"
      stroke="white"
      strokeWidth="10"
      r="27"
    />
    <circle
      className="second-circle"
      transform="rotate(-90 32 0)"
      fill="none"
      stroke="black"
      strokeWidth="10"
      strokeDasharray="170,170"
      r="27"
    />
  </svg>
);

// @used in ROAD
export const Spinner = styled(SpinnerIcon)`
  @keyframes spinning {
    0% {
      stroke-dashoffset: 340;
    }
    50% {
      stroke-dashoffset: 170;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  width: 54px;
  height: 54px;

  ${mediaMin.md} {
    width: 70px;
    height: 70px;
  }

  .first-circle {
    stroke: ${colors.DW_GREY_11};
  }
  .second-circle {
    stroke: ${colors.DW_LIGHT_BLUE_NEW};
    animation: spinning 2000ms cubic-bezier(0.43, 0, 0.09, 1) infinite normal forwards;
  }
`;
