import { styled } from '@linaria/react';
import { GtmScriptWithDataLayer } from '../components/GoogleTagManager';
import { GTM_NO_CONTENT_PAGES } from '../constants/pageTypes';
import { I18nText } from '../components/I18n/I18nText.jsx';
import { getDashboardUrl } from '../utils/url/urlFactory';
import NotFoundPageImage from '../assets/svg/yogis.svg';
import { useTranslation } from '../hooks/useTranslation';
import { colors, mediaMin } from '../utils/css';
import { LinkButton } from '../components/LinkButton/LinkButton.jsx';
import { useLanguage } from '../context/LanguageContext';
import { DetailPageHeadline } from '../components/Headline/Headline.jsx';
import { DetailIntroText } from '../components/Text/Text.jsx';

const Default404Page = ({ className }) => {
  const { langCode } = useLanguage();

  const imgAlt = useTranslation('not_found_404.illustration.alt');
  const imgLabel = useTranslation('not_found_404.illustration.label');

  return (
    <div className={className}>
      <img src={NotFoundPageImage} alt={imgAlt} title={imgLabel} />
      <GtmScriptWithDataLayer noContentPageName={GTM_NO_CONTENT_PAGES.NOT_FOUND} />
      <div className="text-container">
        <I18nText isA={DetailPageHeadline} translation="not_found_404.headline" />
        <I18nText
          isA={DetailIntroText}
          as="span"
          className="highlight"
          translation="not_found_404.message.part_one"
        />
        <span> </span>
        <I18nText isA={DetailIntroText} as="span" translation="not_found_404.message.part_two" />
      </div>
      <LinkButton
        to={getDashboardUrl(langCode)}
        titleKey="not_found_404.homepage_link.to_homepage"
      ></LinkButton>
    </div>
  );
};

const StyledDefault404Page = styled(Default404Page)`
  display: grid;
  padding-top: 34px;
  padding-bottom: 30px;
  padding-left: 45px;
  padding-right: 45px;

  img {
    justify-self: center;
    width: 50%;
  }

  .text-container {
    padding-top: 20px;
    > span.highlight {
      color: ${colors.DW_LIGHT_BLUE_NEW};
    }
  }

  ${LinkButton} {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  ${mediaMin.md} {
    padding-top: 29px;
    padding-bottom: 53px;
    padding-left: 40px;
    padding-right: 40px;

    ${LinkButton} {
      margin-top: 30px;
    }
  }
  ${mediaMin.lg} {
    grid-template-columns: 30% auto;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 75vh;
    align-content: center;

    img {
      width: 100%;
    }
    .text-container {
      align-self: center;
      padding: 0;
      padding-inline-start: 30px;
    }
    ${LinkButton} {
      margin-top: 15px;
      grid-column: 1 / 3;
    }
  }
`;

export default StyledDefault404Page;
