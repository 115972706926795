import { styled } from '@linaria/react';
import { useSelector } from 'react-redux';
import { useNavigationContext } from '../NavigationContainer/NavigationContext.jsx';
import { userIsLoggedIn } from '../../../state/user/userSelectors';
import { useGlobalsContext } from '../../../context/GlobalsContext';
import { keyCodes } from '../../../constants/keyCodes';
import { StyledBurgerButtonIcon as BurgerButtonIcon } from '../../../assets/icons/BurgerButtonIcon.jsx';
import { colors } from '../../../utils/css';

export const BurgerButton = ({ className }) => {
  const { isNavMenuOpen, toggleNavMenu } = useNavigationContext();
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { document } = useGlobalsContext();

  return (
    <button
      onClick={toggleNavMenu}
      type="button"
      className={`${className} ${isNavMenuOpen ? 'open' : 'closed'}`}
      data-toggle="collapse"
      data-target="#nav-user-menu"
      data-testid="openMainMenuButton"
      aria-haspopup="true"
      aria-controls="navbar"
      aria-expanded={isNavMenuOpen}
      onKeyDown={evt => {
        if (isNavMenuOpen && evt.shiftKey && evt.key === keyCodes.TAB) {
          const lastEntry = document.querySelector(
            isLoggedIn ? '#signOutButton>*' : '#signInButton>*',
          );
          if (lastEntry) {
            evt.preventDefault();
            lastEntry.focus();
          } else {
            console.error("Can't find expected entries in navmenu");
          }
        }
      }}
    >
      <BurgerButtonIcon />
      <span className="sr-only">Toggle navigation</span>
    </button>
  );
};

export const StyledBurgerButton = styled(BurgerButton)`
  background: transparent;
  border-radius: 1px;
  background-clip: content-box;
  border: 2px solid transparent;

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;

  &:hover {
    border-radius: 4px;
    border-color: ${colors.DW_DARK_BLUE_NEW};
  }

  ${BurgerButtonIcon} {
    path {
      stroke: none;
    }
    g {
      transform: rotate(0);
      transform-origin: 50%;
      transition: transform 300ms cubic-bezier(0.91, 0, 0.57, 1);
    }

    .top-line path,
    .bottom-line path {
      transform: translateY(0);
      transition: transform 100ms cubic-bezier(0.43, 0, 0.09, 1) 200ms;
    }
  }

  &.open {
    ${BurgerButtonIcon} {
      g {
        transform: rotate(-225deg);
        transition: transform 500ms cubic-bezier(0.43, 0, 0.09, 1);
      }

      path {
        transition: transform 167ms cubic-bezier(0.43, 0, 0.09, 1);
      }

      .top-line path {
        transform: translateY(7px);
      }

      .bottom-line {
        transform: rotate(-135deg);

        path {
          transform: translateY(-7px);
        }
      }
    }
  }
`;
