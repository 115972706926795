import { useEffect, useRef } from 'react';
import { isServer } from '../utils/ssr';

export const useTimeout = ({ callback, delay = 100, conditional = true }, triggers = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (isServer() || !conditional) {
      return undefined;
    }
    const handleId = setTimeout(tick, delay);
    return () => clearTimeout(handleId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, conditional, ...triggers]);
};
