import { styled } from '@linaria/react';
import { colors, mediaMin, resolutions } from '../../utils/css';

const PageLayout = ({ className, children }) => (
  <div className={className} aria-label="Page Container">
    <div className="content-container">{children}</div>
  </div>
);

export const StyledPageLayout = styled(PageLayout)`
  background: ${colors.LG_WHITE};
  display: grid;
  margin: 4rem auto 0;
  overflow-x: hidden !important;
  padding: 0;

  ${mediaMin.xl} {
    min-height: 600px;
    padding: 0;
    width: ${resolutions.min.xl}px;

    .content-container {
      width: ${resolutions.min.xl}px;
    }
  }
`;
