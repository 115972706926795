import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useGlobalsContext } from '../../../context/GlobalsContext';
import { isServer } from '../../../utils/ssr';

export const NavigationContext = createContext({});

const TRADITIONAL_CHIN_LOCAL_STORAGE_KEY = 'isTraditionalChin';

export const NavigationContextProvider = ({ children }) => {
  const { localStorage } = useGlobalsContext();
  const [isTraditionalChin, setInnerState] = useState(() =>
    isServer() ? false : localStorage.getItem(TRADITIONAL_CHIN_LOCAL_STORAGE_KEY) === 'true',
  );
  const setIsTraditionalChin = useCallback(
    newVal => {
      localStorage.setItem(TRADITIONAL_CHIN_LOCAL_STORAGE_KEY, newVal ? 'true' : 'false');
      setInnerState(newVal);
    },
    [localStorage],
  );

  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [isLanguageSubMenuOpen, setIsLanguageSubMenuOpen] = useState(false);
  const [languageSubMenuWasOpen, setLanguageSubMenuWasOpen] = useState(false);

  const contextValue = useMemo(() => {
    const toggleNavMenu = () => {
      setIsNavMenuOpen(o => !o);
      setIsLanguageSubMenuOpen(false);
    };

    const toggleLanguageSubNavMenu = () => {
      setIsLanguageSubMenuOpen(o => !o);
      setLanguageSubMenuWasOpen(true);
    };

    const closeMenu = () => {
      setIsNavMenuOpen(false);
      setIsLanguageSubMenuOpen(false);
    };

    return {
      isTraditionalChin,
      setIsTraditionalChin,
      isNavMenuOpen,
      isLanguageSubMenuOpen,
      toggleNavMenu,
      toggleLanguageSubNavMenu,
      closeMenu,
      languageSubMenuWasOpen,
    };
  }, [
    isLanguageSubMenuOpen,
    isNavMenuOpen,
    isTraditionalChin,
    languageSubMenuWasOpen,
    setIsTraditionalChin,
  ]);

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};

// TODO: Remove and update test mocks
export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
