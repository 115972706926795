import { useFrontendConfig } from '../../context/FrontendConfigContext';

export const noScriptUrl = (gtmId, dataLayer) =>
  `https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}&${new URLSearchParams(dataLayer)}`;

// @used in ROAD
export const GtmNoScriptFallback = ({ dataLayer }) => {
  const { gtmId } = useFrontendConfig();
  return (
    <noscript>
      <iframe
        title={`GTM-${gtmId}`}
        src={noScriptUrl(gtmId, dataLayer)}
        height="0"
        width="0"
        style={{
          display: 'none',
          visibility: 'hidden',
        }}
      />
    </noscript>
  );
};
