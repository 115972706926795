import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import StyledDefault404Page from '../pages/Default404Page.jsx';
import { pathPartials } from '../utils/url/pathAnalyser';
import {
  PAGE_GRAMMAR,
  PAGE_LOGIN,
  PAGE_OVERVIEW,
  PAGE_PASSWORD_CHANGE,
  PAGE_PASSWORD_RESET,
  PAGE_PLACEMENT_TEST,
  PAGE_PROFILE,
  PAGE_REGISTRATION,
  PAGE_VOCABULARY,
  PAGE_VOCABULARY_TRAINER,
  PAGE_VOCABULARY_TRAINER_START,
  STATUS_FEEDBACK,
} from '../utils/url/urlFactory';
import { RestrictedRoute } from './RestrictedRoute/RestrictedRoute.jsx';
import { PageContainer, MetadataAndGtm } from './Page/PageContainer.jsx';
import { PAGE_TYPES } from '../constants/pageTypes';
import { LanguageContext } from '../context/LanguageContext';
import { isRtlByLangCode } from '../utils/mappers/rtlLanguages';
import { ErrorBoundary } from './ErrorBoundary/ErrorBoundary.jsx';

const RedirectToLandingPage = lazy(() => import('./Redirect/RedirectToLandingPage.jsx'));
const LessonPage = lazy(() => import('../pages/LessonPage.jsx'));
const CoursePage = lazy(() => import('../pages/CoursePage.jsx'));
const FinalTestResultPage = lazy(() => import('../pages/FinalTestResultPage.jsx'));
const PlacementTestResultPage = lazy(() => import('../pages/PlacementTestResultPage.jsx'));
const NavigationPage = lazy(() => import('../pages/NavigationPage.jsx'));
const ContentDetailPage = lazy(() => import('../pages/ContentDetailPage.jsx'));
const GrammarOverviewPage = lazy(() => import('../pages/GrammarOverviewPage.jsx'));
const HelpPage = lazy(() => import('../pages/HelpPage.jsx'));
const VendorConsentPage = lazy(() => import('../pages/VendorConsentPage.jsx'));
const VocabularyPage = lazy(() => import('../pages/VocabularyPage.jsx'));
const StyledRegistration = lazy(() => import('./user/Registration.jsx'));
const SetNewPassword = lazy(() => import('./user/SetNewPassword.jsx'));
const ConfirmUserRegistrationContainer = lazy(() =>
  import('./user/ConfirmUserRegistrationContainer.jsx'),
);
const ConfirmEmailChangeContainer = lazy(() => import('./user/ConfirmEmailChangeContainer.jsx'));
const StyledUserProfile = lazy(() => import('./user/StyledUserProfile.jsx'));
const PasswordChange = lazy(() => import('./user/PasswordChange.jsx'));
const VocabularyTrainer = lazy(() => import('./VocabularyTrainer/VocabularyTrainer.jsx'));
const PasswordReset = lazy(() => import('./user/PasswordReset.jsx'));
const StyledLogin = lazy(() => import('./user/Login.jsx'));
const StyledStatusFeedback = lazy(() => import('./StatusFeedback/StatusFeedback.jsx'));
const PlacementTestPage = lazy(() => import('../pages/PlacementTestPage.jsx'));

export const Pages = () => {
  const { path } = useRouteMatch();
  const { langCode } = useParams();

  return (
    <LanguageContext.Provider value={{ langCode, isRtl: isRtlByLangCode(langCode) }}>
      <ErrorBoundary>
        <PageContainer>
          <Switch>
            <Route exact path={`${path}${PAGE_OVERVIEW}`}>
              <RedirectToLandingPage />
            </Route>
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/l-:lessonId(${pathPartials.contentIdRegex})`}
            >
              <LessonPage />
            </Route>
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/c-:contentId(${pathPartials.contentIdRegex})`}
              render={({ match: { params } }) => <CoursePage {...params} />}
            />
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/gr-:contentId(${pathPartials.contentIdRegex})`}
              render={({ match: { params } }) => (
                <Redirect to={`/${langCode}/grammar#${params.contentId}`} />
              )}
            />
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/placement-:contentId(${pathPartials.contentIdRegex})`}
              render={({ match: { params } }) => <PlacementTestResultPage {...params} />}
            />
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/final-:contentId(${pathPartials.contentIdRegex})`}
              render={({ match: { params } }) => <FinalTestResultPage {...params} />}
            />
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/s-:contentId(${pathPartials.contentIdRegex})`}
            >
              <NavigationPage />
            </Route>
            <Route
              path={`${path}/:title(${pathPartials.titlePathRegex})?/a-:contentId(${pathPartials.contentIdRegex})`}
              render={({ match: { params } }) => <ContentDetailPage {...params} />}
            />
            <Route exact path={`${path}${PAGE_GRAMMAR}`}>
              <GrammarOverviewPage />
            </Route>
            <Route exact path={`${path}/help`}>
              <HelpPage />
            </Route>
            <Route
              exact
              path={`${path}/:title(${pathPartials.titlePathRegex})?/privacy-settings-:langCode(${pathPartials.langCodeRegex})`}
            >
              <VendorConsentPage />
            </Route>
            <Route exact path={`${path}${PAGE_VOCABULARY}`}>
              <VocabularyPage />
            </Route>
            <Route exact path={`${path}${PAGE_REGISTRATION}`}>
              <MetadataAndGtm pageType={PAGE_TYPES.REGISTER_USER} useDescriptionTranslation>
                <StyledRegistration />
              </MetadataAndGtm>
            </Route>
            <Route
              path={`${path}/user/password/set`}
              render={({ location }) => (
                <MetadataAndGtm
                  pageType={PAGE_TYPES.PASSWORD_SET}
                  addDescription={false}
                  noSearchEngine
                >
                  <SetNewPassword
                    confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                  />
                </MetadataAndGtm>
              )}
            />
            <Route
              path={`${path}/user/register/confirm`}
              render={({ location }) => (
                <>
                  <Helmet>
                    <meta name="robots" content="none" />
                  </Helmet>
                  <ConfirmUserRegistrationContainer
                    confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                  />
                </>
              )}
            />
            <Route
              path={`${path}/user/email/change`}
              render={({ location }) => (
                <>
                  <Helmet>
                    <meta name="robots" content="none" />
                  </Helmet>
                  <ConfirmEmailChangeContainer
                    confirmationId={new URLSearchParams(location.search).get('confirmationId')}
                  />
                </>
              )}
            />
            <RestrictedRoute exact path={`${path}${PAGE_PROFILE}`}>
              <MetadataAndGtm
                pageType={PAGE_TYPES.USER_PROFILE}
                addDescription={false}
                noSearchEngine
              >
                <StyledUserProfile />
              </MetadataAndGtm>
            </RestrictedRoute>
            <RestrictedRoute exact path={`${path}${PAGE_PASSWORD_CHANGE}`}>
              <MetadataAndGtm
                pageType={PAGE_TYPES.PASSWORD_CHANGE}
                addDescription={false}
                noSearchEngine
              >
                <PasswordChange />
              </MetadataAndGtm>
            </RestrictedRoute>
            <RestrictedRoute
              exact
              path={[
                `${path}${PAGE_VOCABULARY_TRAINER}`,
                `${path}${PAGE_VOCABULARY_TRAINER_START}`,
              ]}
            >
              <MetadataAndGtm pageType={PAGE_TYPES.VOCABULARY_TRAINER} addDescription={false}>
                <VocabularyTrainer />
              </MetadataAndGtm>
            </RestrictedRoute>
            <Route exact path={`${path}${PAGE_PASSWORD_RESET}`}>
              <MetadataAndGtm pageType={PAGE_TYPES.PASSWORD_RESET} addDescription={false}>
                <PasswordReset />
              </MetadataAndGtm>
            </Route>
            <Route exact path={`${path}${PAGE_LOGIN}`}>
              <MetadataAndGtm pageType={PAGE_TYPES.LOGIN} useDescriptionTranslation noSearchEngine>
                <StyledLogin />
              </MetadataAndGtm>
            </Route>
            <Route
              exact
              path={`${path}${STATUS_FEEDBACK}/:feedbackType(${pathPartials.feedbackTypes})`}
            >
              <MetadataAndGtm
                pageType={PAGE_TYPES.FEEDBACK_STATUS}
                addDescription={false}
                noSearchEngine
              >
                <StyledStatusFeedback />
              </MetadataAndGtm>
            </Route>
            <Route exact path={`${path}${PAGE_PLACEMENT_TEST}`}>
              <PlacementTestPage />
            </Route>
            <Route exact path={path}>
              <Redirect to={`/${langCode}/overview`} />
            </Route>
            <Route path={path}>
              <StyledDefault404Page />
            </Route>
          </Switch>
        </PageContainer>
      </ErrorBoundary>
    </LanguageContext.Provider>
  );
};

export const App = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/en/overview" />
      </Route>
      <Route path={`/:langCode(${pathPartials.langCodeRegex})`}>
        <Pages />
      </Route>
      <Route>
        <PageContainer>
          <StyledDefault404Page />
        </PageContainer>
      </Route>
    </Switch>
  );
};
