import { css, cx } from '@linaria/core';
import { Link as RouterLink } from 'react-router-dom';
import { removeZhongwenUrlSearchParamFromPath } from '../../utils/url/url';

const style = css`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;

export const Link = ({ className, native = false, children, to, ...restProps }) => {
  const Tag = native ? 'a' : RouterLink;
  const linkTarget = removeZhongwenUrlSearchParamFromPath(to);
  const linkProperty = native ? { href: linkTarget } : { to: linkTarget };
  const styles = cx(style, className);

  return (
    <Tag className={styles} {...linkProperty} {...restProps}>
      {children}
    </Tag>
  );
};
