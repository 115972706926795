export const resolutions = {
  min: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
  max: {
    xs: 599,
    sm: 767,
    md: 1023,
    lg: 1439,
    xl: Number.MAX_SAFE_INTEGER,
  },
};

const maxColumnWidth = resolutions.min.xl / 30;
export const columnSize = {
  c_half: `min(1.66667vw,${maxColumnWidth * 0.5}px)`,
  c1: `min(3.33333vw,${maxColumnWidth * 1}px)`,
  c2: `min(6.66667vw,${maxColumnWidth * 2}px)`,
  c3: `min(10vw,${maxColumnWidth * 3}px)`,
  c4: `min(13.33333vw,${maxColumnWidth * 4}px)`,
  c6: `min(20vw,${maxColumnWidth * 6}px)`,
  c8: `min(26.66667vw,${maxColumnWidth * 8}px)`,
  c10: `min(33.33333vw,${maxColumnWidth * 10}px)`,
  c14: `min(46.66667vw,${maxColumnWidth * 14}px)`,
  c16: `min(53.33333vw,${maxColumnWidth * 16}px)`,
  c18: `min(60vw,${maxColumnWidth * 18}px)`,
  c22: `min(73.33333vw,${maxColumnWidth * 22}px)`,
  c24: `min(80vw,${maxColumnWidth * 24}px)`,
  c26: `min(86.66667vw,${maxColumnWidth * 26}px)`,
  c28: `min(93.33333vw,${maxColumnWidth * 28}px)`,
};

const wrapInMedia = (sizes, type) =>
  Object.keys(sizes).reduce(
    (acc, label) => ({
      ...acc,
      [label]: `@media (${type}-width:${sizes[label]}px)`,
    }),
    {},
  );

export const mediaMin = wrapInMedia(resolutions.min, 'min');
export const mediaMax = wrapInMedia(resolutions.max, 'max');

export const aspectRatioFormats = {
  wide: '16/7',
  ultraWide: '21/9',
  square: '1/1',
  squarish: '4/3',
};

/* https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134 */
/* https://media.kulturbanause.de/2017/05/interaction-type-media-queries.html */
export const mediaDevice = {
  mouse: `@media (hover: hover) and (pointer: fine)`,
};

export const aspectRatioAware = {
  xl: {
    ultraWide: `@media (min-width: ${resolutions.min.xl}px) and (min-aspect-ratio: ${aspectRatioFormats.wide})`,
  },
};

export const colors = {
  LG_WHITE: '#FFF',
  LG_BLACK: '#000',

  LG_ORANGE: '#F08C00',
  LG_ORANGE_DARK: '#E1500F',
  LG_PINK: '#DC1978',
  LG_RED: '#BE0046',
  LG_GREEN_LIGHT: '#91B423',
  LG_GREEN: '#55911E',

  DW_GREY_02: '#E4E8EB',
  DW_GREY_03: '#D8DDE2',
  DW_GREY_07: '#A1ABB4',
  DW_GREY_11: '#5C666E',

  DW_DARK_BLUE: '#032F59',
  DW_LIGHT_BLUE: '#00A5FF',
  DW_LIGHT_GREEN: '#C8D205',

  LG_BLUE_1: '#0098FF',
  LG_BLUE_2: '#0096FA',
  LG_BLUE_4: '#99d7ff',
  LG_BLUE_5: '#1464A5',
  LG_BLUE_6: '#9ec3de',

  LG_ORANGE1: '#bf6f00',

  LG_GRAY_TRANSPARENT_1: 'rgba(128,128,128,.5)',
  LG_TRANSPARENT_BLACK_075: 'rgba(0,0,0,0.075)',
  LG_TRANSPARENT_BLACK_20: 'rgba(0,0,0,0.2)',
  LG_TRANSPARENT_BLACK_25: 'rgba(0,0,0,0.25)',
  LG_TRANSPARENT_BLACK_40: 'rgba(0,0,0,0.4)',
  LG_TRANSPARENT_BLACK_50: 'rgba(0,0,0,0.5)',
  LG_TRANSPARENT_WHITE_10: 'rgba(255,255,255,0.1)',
  LG_TRANSPARENT_WHITE_15: 'rgba(255,255,255,0.15)',

  // NEW design
  DW_DARK_BLUE_NEW: '#002186',
  DW_LIGHT_BLUE_NEW: '#05B2FC',
  DW_YELLOW: '#FAE000',
  BLUE_GREY_01: '#F0F6FA',
  BLUE_GREY_02: '#CDE1EE',
  BLUE_GREY_03: '#99B5C9',
  BLUE_GREY_04: '#5C718A',
  BLUE_GREY_05: '#445D7B',
  DARK_BLUE_GREY_01: '#081336',
  DARK_BLUE_GREY_02: '#000821',
  WARM_GREY_01: '#F4F2F2',
  WARM_GREY_02: '#E0DFDE',
  WARM_GREY_03: '#CFCCCA',
  ACCENT_GREEN: '#63DE9D',
  ACCENT_RED: '#EF6C6C',
  BREAKING_RED: '#BE232D',
};

export const fontSize = {
  minus1: 'font-size: 0.83rem;',
  base: 'font-size: 1rem;',
  plus1: 'font-size: 1.2rem;',
  plus2: 'font-size: 1.44rem;',
  plus3: 'font-size: 1.73rem;',
  plus4: 'font-size: 2.07rem;',
};

export const clearfix = `
    &:after {
      display: table;
      content: "";
      clear: both;
    }
  `;
