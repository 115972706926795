import { useI18nContext } from '../context/I18nContext.jsx';

export const useTranslation = translation => {
  const i18n = useI18nContext();

  if (typeof translation === 'string') {
    return i18n.t(translation);
  }

  if (!translation?.key) {
    return null;
  }

  return i18n.t(translation.key, translation.parameters);
};
