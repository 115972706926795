import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalsContext } from '../context/GlobalsContext';

export const useScrollToTop = () => {
  const { window } = useGlobalsContext();
  const { listen } = useHistory();

  useEffect(
    () =>
      listen((_, action) => {
        if (action !== 'POP') {
          window.scrollTo(0, 0);
        }
      }),
    [window, listen],
  );
};
