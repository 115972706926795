import { Helmet } from 'react-helmet-async';
import { useMetadata } from '../../context/MetadataContext';

export const PageLanguage = () => {
  const iso639Lang = useMetadata();
  return (
    <Helmet>
      <html lang={iso639Lang} />
    </Helmet>
  );
};
