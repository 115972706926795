import { styled } from '@linaria/react';
import { StyledStandardButton } from '../StandardButton/StandardButton.jsx';
import { Link } from '../Link/Link.jsx';

const LinkButtonComponent = ({ native = false, to, ...props }) => {
  const linkProperty = native ? { href: to } : { to };
  return <StyledStandardButton isA={native ? 'a' : Link} {...linkProperty} {...props} />;
};

export const LinkButton = styled(LinkButtonComponent)`
  display: inline-block; /* height is ignored without this */

  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
