import { isServer } from '../../utils/ssr';
import { GtmLoadScript } from './GtmLoadScript.jsx';
import { GtmDataLayer } from './GtmDataLayer.jsx';
import { useGlobalsContext } from '../../context/GlobalsContext';

const noContent = {};

// @used in ROAD
export const GtmScriptWithDataLayer = ({ content = noContent, noContentPageName, lessonItem }) => {
  const { window } = useGlobalsContext();
  const isGtmAlreadyInitialized = !isServer() && !!window.dataLayer;

  return (
    <>
      <GtmDataLayer
        content={content}
        noContentPageName={noContentPageName}
        lessonItem={lessonItem}
      />
      {!isGtmAlreadyInitialized && <GtmLoadScript />}
    </>
  );
};
