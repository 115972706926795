import { styled } from '@linaria/react';
import { colors, fontSize } from '../../utils/css';

export const NavigationLinkInteraction = styled.span`
  ${fontSize.base}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const PageLinkInteraction = styled.span`
  ${fontSize.base}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const richtextLinkInteractionStyles = `
  ${fontSize.base}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: underline;

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const ButtonInteraction = styled.button`
  ${fontSize.base}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1rem;
  font-weight: 600;
  text-align: center;

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const FooterLinkInteraction = styled.span`
  ${fontSize.minus1}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.4;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;
