import { styled } from '@linaria/react';
import { ReactComponent as TickImg } from '../../../../../assets/svg/tick.svg';
import { getDashboardUrl } from '../../../../../utils/url/urlFactory';
import { useI18nContext } from '../../../../../context/I18nContext.jsx';
import {
  CHINESE_SIMP_TRANSLATION_KEY,
  CHINESE_TRAD_TRANSLATION_KEY,
} from '../../../../../constants/menuLanguageCodes';
import {
  URL_GET_PARAM_NAME,
  URL_GET_PARAM_SIMPLIFIED,
  URL_GET_PARAM_TRADITIONAL,
} from '../../../useChineseSimpleTraditionalSwitch';
import { isLanguageSelected } from './langUtils';
import { useNavigationContext } from '../../NavigationContext.jsx';
import { NavigationLinkInteraction } from '../../../../Interaction/Interaction.jsx';

const getDashBoardUrlForLanguage = ({ languageTranslationKey, language }) => {
  const dashboardBaseUrl = getDashboardUrl(language);
  if (languageTranslationKey === CHINESE_SIMP_TRANSLATION_KEY) {
    return `${dashboardBaseUrl}?${URL_GET_PARAM_NAME}=${URL_GET_PARAM_SIMPLIFIED}`;
  }
  if (languageTranslationKey === CHINESE_TRAD_TRANSLATION_KEY) {
    return `${dashboardBaseUrl}?${URL_GET_PARAM_NAME}=${URL_GET_PARAM_TRADITIONAL}`;
  }
  return dashboardBaseUrl;
};

export const LanguageSelectionButton = ({
  language,
  translationKey: languageTranslationKey,
  langCode: currentLanguage,
}) => {
  const i18n = useI18nContext();
  const { isTraditionalChin } = useNavigationContext();
  const isSelected = isLanguageSelected({
    languageTranslationKey,
    currentLanguage,
    isTraditionalChin,
  });

  return (
    <StyledLanguageSelectionButton>
      <NavigationLinkInteraction
        as="a"
        href={getDashBoardUrlForLanguage({ languageTranslationKey, language })}
      >
        <span>{i18n.t(`header.menu.language.${languageTranslationKey}`)}</span>
        <span> - </span>
        <span>{i18n.t(`header.menu.language.native.${languageTranslationKey}`)}</span>
        {isSelected && (
          <i className="icon">
            <TickImg />
          </i>
        )}
      </NavigationLinkInteraction>
    </StyledLanguageSelectionButton>
  );
};

export const StyledLanguageSelectionButton = styled('li')`
  display: block;
  float: inline-start;
  width: 100%;
  margin: 10px;

  &:first-child {
    margin-top: 20px;
  }

  i {
    margin: 0 10px;
  }
`;
