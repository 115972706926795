const isValidHtmlElement = elem => elem && elem instanceof HTMLElement;

function isFixed(passedDocument, elem) {
  if (!isValidHtmlElement(elem)) {
    return false;
  }
  const pos = passedDocument.defaultView.getComputedStyle(elem).getPropertyValue('position');
  return pos === 'fixed';
}

function hasParentWithFixedPosition(passedDocument, elem, acc = false) {
  if (!isValidHtmlElement(elem)) {
    return acc;
  }
  return hasParentWithFixedPosition(
    passedDocument,
    elem.parentNode,
    acc || isFixed(passedDocument, elem),
  );
}

function offsetOf(passedDocument, passedWindow, elem, hasFixedParent = false) {
  const rect = elem.getBoundingClientRect();
  const clientLeft = passedDocument.documentElement.clientLeft || passedDocument.body.clientLeft;
  const clientTop = passedDocument.documentElement.clientTop || passedDocument.body.clientTop;

  if (hasFixedParent) {
    return {
      top: rect.top - clientTop,
      left: rect.left - clientLeft,
    };
  }
  const scrollLeft =
    passedWindow.pageXOffset ||
    passedDocument.documentElement.scrollLeft ||
    passedDocument.body.scrollLeft;
  const scrollTop =
    passedWindow.pageYOffset ||
    passedDocument.documentElement.scrollTop ||
    passedDocument.body.scrollTop;
  return {
    top: rect.top + scrollTop - clientTop,
    left: rect.left + scrollLeft - clientLeft,
  };
}

function getMeasurableElem(elem) {
  if (!isValidHtmlElement(elem)) {
    return null;
  }
  if (elem.offsetWidth > 0) {
    return elem;
  }
  const firstChildElem = elem.children[0];
  return getMeasurableElem(firstChildElem);
}

const toPx = obj =>
  Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [key]: `${value}px` }), {});

export function positionOf(passedDocument, passedWindow, focusedTarget) {
  const measurableElem = getMeasurableElem(focusedTarget);
  if (!isValidHtmlElement(measurableElem)) {
    return toPx({
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    });
  }
  const hasFixedParent = hasParentWithFixedPosition(passedDocument, focusedTarget);
  const offset = offsetOf(passedDocument, passedWindow, measurableElem, hasFixedParent);
  const position = hasFixedParent ? 'fixed' : 'absolute';

  return {
    position,
    ...toPx({
      left: offset.left,
      top: offset.top,
      width: measurableElem.offsetWidth,
      height: measurableElem.offsetHeight,
    }),
  };
}

export function getZIndex(passedDocument, elem, acc = 0) {
  if (!isValidHtmlElement(elem)) {
    return acc;
  }
  const z = passedDocument.defaultView.getComputedStyle(elem).getPropertyValue('z-index');
  if (isNaN(z)) {
    return getZIndex(passedDocument, elem.parentNode, acc);
  }
  return getZIndex(passedDocument, elem.parentNode, acc + +z);
}
