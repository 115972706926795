import { styled } from '@linaria/react';
import { colors, fontSize } from '../../utils/css';

export const KickerInfo = styled.span`
  ${fontSize.minus1}
  color: ${colors.BLUE_GREY_04};
  line-height: 1.1;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  .theme-dark & {
    color: ${colors.BLUE_GREY_03};
  }
`;

export const DateTimeInfo = styled.span`
  ${fontSize.minus1}
  color: ${colors.BLUE_GREY_04};
  line-height: 1.1;

  .theme-dark & {
    color: ${colors.BLUE_GREY_03};
  }
`;

export const CopyrightInfo = styled.span`
  ${fontSize.minus1}
  color: ${colors.BLUE_GREY_04};
  line-height: 1.1;
  font-style: italic;

  .theme-dark & {
    color: ${colors.BLUE_GREY_03};
  }
`;
